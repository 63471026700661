<template>
	<div>
		<div class="text-center mb-10">
			<h1 class="headline mb-2">
				{{ $vuetify.lang.t('$vuetify.signin.success.title') }}
			</h1>
			<v-chip
				class="mb-0"
				outlined
				link
				@click="signout"
			>
				<v-avatar left>
					<v-icon color="secondary">
						mdi-account-circle
					</v-icon>
				</v-avatar>
				{{ $store.state.auth.identifier }}
				<v-avatar right>
					<v-icon color="secondary">
						mdi-chevron-down
					</v-icon>
				</v-avatar>
			</v-chip>
		</div>

		<div class="text-body-2 text--secondary">
			{{ $vuetify.lang.t('$vuetify.signin.success.subtitle') }}
		</div>
		<div class="d-flex justify-space-between mt-10">
			<v-btn
			  class="text-none ml-auto"
			  style="min-width: 88px;"
			  color="primary"
			  depressed
			  @click="next"
			>
				{{ $vuetify.lang.t('$vuetify.signin.success.action.next') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	name: 'signin-success',
	props: {
		query: { type: Object, default() { return {} } },
	},
	metaInfo() {
      	return {
	      	title: this.$vuetify.lang.t('$vuetify.signin.success.title'),
	      	titleTemplate: '%s | ' + this.$vuetify.lang.t('$vuetify.header.title', { brand: this.$whitelabel.getClient().name }),
	      	htmlAttrs: { lang: this.$i18n.locale }
      	}
    },
	mounted() {
		this.$emit('progress', false);

		this.$nextTick(() => {
			setTimeout(() => {
				this.next();
			}, 3000);
		})
	},
	methods: {
		signout() {
			this.$emit('progress', true);
			this.$store.dispatch("auth/signout").then(response => {
				this.$router.push({ name: 'signin-identifier', query: this.query });
			}).catch(error => {
				this.$router.push({ name: 'rejected-server', query: this.query });
			});
		},
		next() {
			this.$emit('progress', true);
			window.location.href = this.query.continue;
		}
	}
}
</script>