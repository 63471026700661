<template>
	<div>
		<div class="text-center mb-10">
			<h1 class="headline mb-2">
				{{ $vuetify.lang.t('$vuetify.signin.password.title') }}
			</h1>
			<v-chip
				class="mb-0"
				outlined
				link
				@click="$emit('progress', true);$router.push({ name: 'signin-identifier', query: query })"
			>
				<v-avatar left>
					<v-icon color="secondary">
						mdi-account-circle
					</v-icon>
				</v-avatar>
				{{ $store.state.auth.identifier }}
				<v-avatar right>
					<v-icon color="secondary">
						mdi-chevron-down
					</v-icon>
				</v-avatar>
			</v-chip>
		</div>

		<v-form @submit.prevent="next" autocomplete="off">
			<input type="text" class="d-none" name="username" :value="$store.state.auth.identifier">
			<v-text-field
				dense
				ref="password"
				v-model.trim="password"
				class="mb-0"
				:append-icon="showpassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
				@click:append="showpassword = !showpassword"
				:type="showpassword ? 'text' : 'password'"
				:label="$vuetify.lang.t('$vuetify.signin.password.form.password.label')"
				name="password"
				hide-details="auto"
				outlined
				:error-messages="passwordError"
				@input="$v.password.$touch()"
				autocomplete="password"
				required
			/>
		</v-form>

		<div class="d-flex justify-space-between mt-10">
			<v-btn
				class="text-none letter-spacing-0"
				style="margin-left: -16px;"
				color="primary"
				text
				@click="$router.push({ name: 'forgot-password-request', query: query })"
			>
				{{ $vuetify.lang.t('$vuetify.signin.password.action.forgot-password') }}
			</v-btn>
			<v-btn
			  :disabled="$v.$invalid"
			  class="text-none"
			  style="min-width: 88px;"
			  color="primary"
			  depressed
			  @click="next"
			>
				{{ $vuetify.lang.t('$vuetify.signin.password.action.next') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
	name: 'signin-password',
	props: {
		query: { type: Object, default() { return {} } },
	},
	metaInfo() {
      	return {
	      	title: this.$vuetify.lang.t('$vuetify.signin.password.title'),
	      	titleTemplate: '%s | ' + this.$vuetify.lang.t('$vuetify.header.title', { brand: this.$whitelabel.getClient().name }),
	      	htmlAttrs: { lang: this.$i18n.locale }
      	}
    },
	validations: {
		password: { required },
    },
	data: () => ({
		error: null,
		showpassword: false,
		password: ''
	}),
	computed: {
		passwordError() {
			if ( ! this.$v.password.$dirty) {
				return null;
			} else if ( ! this.$v.password.required) { 
				return this.$vuetify.lang.t('$vuetify.signin.password.form.password.error.required');
			} else if (this.$i18n.te('$vuetify.signin.password.form.password.error.'+this.error)) {
				return this.$vuetify.lang.t('$vuetify.signin.password.form.password.error.'+this.error, { brand: this.$whitelabel.getClient().name });
			}

			return this.error;
		}
	},
	mounted() {
		this.$emit('progress', false);
	},
	methods: {
		next() {
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			this.error = null;
			this.$emit('progress', true);

			this.$store.dispatch("auth/signin", { 
				username: this.$store.state.auth.identifier,
				password: this.password
			}).then(response => {
				this.$router.push({ name: 'signin-success', query: this.query });
			}).catch(error => {
				if (error.response && error.response.status == 403) {
					this.$router.push({ name: 'signin-suspended', query: this.query });
				} else if (error.response && error.response.status == 406) {
					this.$router.push({ name: 'signin-verify', query: {...this.query, ...{ token: error.response.data.data.token }}, params: { email: this.$store.state.auth.identifier } });
				} else {
					this.$emit('progress', false);
		            this.error = 'server';

		            if (error.response && error.response.status) {
			            switch(error.response.status) {
						  	case 401:
						  		this.error = 'unauthorized';
						    	break;
						  	case 404:
						   	 	this.error = 'notfound';
						    	break;
							case 400:
								this.error = error.response.data.error.description;
								break;
						  	default:
						    	this.error = 'server';
						}
			        }

					this.$refs.password.focus();
				}
			});
		}
	}
}
</script>