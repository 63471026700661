<style scoped>
	.choice {
		display: grid;
	    align-items: center;
	    grid-template-columns: minmax(20px,1fr) auto minmax(20px,1fr);
	    grid-gap: 19px;
	}

	.choice:after, .choice:before {
	    content: "";
	    border-top: 1px solid rgba(0,0,0,.12);
	}
</style>
<template>
	<div>
		<div class="text-center mb-10">
			<h1 class="headline mb-2">
				{{ $vuetify.lang.t('$vuetify.signin.identifier.title') }}
			</h1>
			<span class="d-inline-block mb-0">{{ $vuetify.lang.t('$vuetify.signin.identifier.subtitle', { brand: $whitelabel.getClient().name }) }}</span>
			<!-- <span class="d-inline-block mb-0">{{ $vuetify.lang.t('$vuetify.signin.identifier.continue', { app: 'Chatbots' }) }}</span> -->
		</div>

		<v-form @submit.prevent="next" autocomplete="off">
			<v-text-field
				dense
				id="identifier"
				ref="identifier"
				v-model.trim="identifier"
				class="mb-0"
				:label="$vuetify.lang.t('$vuetify.signin.identifier.form.identifier.label')"
				name="username"
				type="text"
				hide-details="auto"
				outlined
				:error-messages="identifierError"
				@input="$v.identifier.$touch()"
				autocomplete="off"
				required
			/>
		</v-form>

		<div class="text-body-2 text--secondary mt-2">
			{{ $vuetify.lang.t('$vuetify.signin.identifier.form.private') }}
		</div>
		<div class="d-flex justify-space-between mt-10">
			<v-btn
				class="text-none letter-spacing-0"
				style="margin-left: -16px;"
				color="primary"
				text
				@click="$emit('progress', true);$store.dispatch('alert/hide');$router.push({ name: 'signup-request', query: query })"
			>
				{{ $vuetify.lang.t('$vuetify.signin.identifier.action.signup') }}
			</v-btn>
			<v-btn
			  :disabled="$v.$invalid"
			  class="text-none"
			  style="min-width: 88px;"
			  color="primary"
			  depressed
			  @click="next"
			>
				{{ $vuetify.lang.t('$vuetify.signin.identifier.action.next') }}
			</v-btn>
		</div>
		<div class="text-body-1 my-10 text-center choice text--secondary d-none">
			{{ $vuetify.lang.t('$vuetify.signin.orother') }}
		</div>
		<v-row>
			<v-col
			  cols="12"
			  md="6"
			>	
				<v-btn
				  block
				  class="text-none red--text d-none"
				  :loading="loading.google"
				  @click="google"
				>
					<v-icon
			          left
			          color="danger"
			      	>
			        	mdi-google
			      	</v-icon>
					Google
				</v-btn>
			</v-col>

			<v-col
			  cols="12"
			  md="6"
			>
				<v-btn
				  block
				  class="text-none indigo--text d-none"
				  :loading="loading.facebook"
				  @click="facebook"
				  :disabled="disabled.facebook"
				>
					<v-icon
			          left
			          color="danger"
			      	>
			        	mdi-facebook
			      	</v-icon>
					Facebook
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
	name: 'signin-identifier',
	props: {
		query: { type: Object, default() { return {} } },
	},
	metaInfo() {
      	return {
	      	title: this.$vuetify.lang.t('$vuetify.signin.identifier.title'),
	      	titleTemplate: '%s | ' + this.$vuetify.lang.t('$vuetify.header.title', { brand: this.$whitelabel.getClient().name }),
	      	htmlAttrs: { lang: this.$i18n.locale },
	      	script: [
		      	{ 
		      		src: 'https://connect.facebook.net/' + this.$i18n.locale + '/sdk.js', 
		      		async: true, 
		      		defer: true, 
		      		crossorigin: 'anonymous', 
		      		callback: () => (this.disabled.facebook = false) 
		      	}
		    ]
      	}
    },
	validations: {
		identifier: { required },
    },
	data: () => ({
		error: null,
		identifier: '',
		loading: {
			google: false,
			facebook: false
		},
		disabled: {
			facebook: true
		}
	}),
	computed: {
		identifierError() {
			if ( ! this.$v.identifier.$dirty) {
				return null;
			} else if ( ! this.$v.identifier.required) { 
				return this.$vuetify.lang.t('$vuetify.signin.identifier.form.identifier.error.required');
			} else if (this.error) {
				return this.$vuetify.lang.t('$vuetify.signin.identifier.form.identifier.error.'+this.error, { brand: this.$whitelabel.getClient().name });
			}

			return this.error;
		}
	},
	mounted() {
		this.identifier = this.$store.state.auth.identifier;
		this.$store.commit("auth/setIdentifier", null);

		if (this.query.provider == 'google') {
			this.$store.dispatch('alert/hide');
			this.$emit('progress', true);
			this.$store.dispatch("auth/signin-google", { 
				token: this.query.token
			}).then(() => {
				this.$router.push({ name: 'signin-success', query: { continue: this.query.continue } });
			}).catch(error => {
				if (error.response && error.response.status == 403) {
					this.$store.commit("auth/setIdentifier", error.response.data.data.email);
					this.$router.push({ name: 'signin-suspended', query: { continue: this.query.continue } });
				} else if (error.response && error.response.status == 404) {
					this.$router.push({ name: 'signup-request', query: { ...this.query, ...{ provider: 'google', token: this.query.token }} });
				} else {
					this.$emit('progress', false);
					this.$router.replace({ name: 'signin-identifier', query: { continue: this.query.continue } });
				}
			});
		} else if (this.query.provider == 'facebook') {
			this.$store.dispatch('alert/hide');
			this.$emit('progress', true);
			this.$store.dispatch("auth/signin-facebook", { 
				token: this.query.token
			}).then(() => {
				this.$router.push({ name: 'signin-success', query: { continue: this.query.continue } });
			}).catch(error => {
				if (error.response && error.response.status == 403) {
					this.$store.commit("auth/setIdentifier", error.response.data.data.email);
					this.$router.push({ name: 'signin-suspended', query: { continue: this.query.continue } });
				} else if (error.response && error.response.status == 404) {
					this.$router.push({ name: 'signup-request', query: { ...this.query, ...{ provider: 'facebook', token: this.query.token }} });
				} else {
					this.$emit('progress', false);
					this.$router.replace({ name: 'signin-identifier', query: { continue: this.query.continue } });
				}
			});
		} else {
			this.$emit('progress', false);
		}
	},
	methods: {
		google() {
			if ( ! this.loading.google) {
				this.$store.dispatch('alert/hide');
				this.$emit('progress', true);
				this.loading.google = true;
				this.$gapi.login().then(({ hasGrantedScopes }) => {
					if (hasGrantedScopes) {
						this.loading.google = false;
						var user = this.$gapi.getUserData();

						this.$store.dispatch("auth/signin-google", { 
							token: user.idToken
						}).then(() => {
							this.$store.commit("auth/setIdentifier", user.email);
							this.$router.push({ name: 'signin-success', query: this.query });
						}).catch(error => {
							if (error.response && error.response.status == 403) {
								this.$store.commit("auth/setIdentifier", user.email);
								this.$router.push({ name: 'signin-suspended', query: this.query });
							} else if (error.response && error.response.status == 404) {
								this.$router.push({ name: 'signup-request', query: { ...this.query, ...{ provider: 'google', token: user.idToken }} });
							} else {
								this.loading.google = false;
								this.$emit('progress', false);
								this.$store.dispatch('alert/set', {type: 'error', content: this.$vuetify.lang.t('$vuetify.signin.alert.access.google')});
								this.$store.dispatch('alert/show');
							}
						});
					} else {
						this.loading.google = false;
						this.$emit('progress', false);
						this.$store.dispatch('alert/set', {type: 'error', content: this.$vuetify.lang.t('$vuetify.signin.alert.access.google')});
						this.$store.dispatch('alert/show');
					}
		        }).catch(() => {
		        	this.loading.google = false;
					this.$emit('progress', false);
					this.$store.dispatch('alert/set', {type: 'error', content: this.$vuetify.lang.t('$vuetify.signin.alert.access.google')});
					this.$store.dispatch('alert/show');
		        });
		    }
		},
		facebook() {
			if ( ! this.loading.facebook) {
				this.$store.dispatch('alert/hide');
				this.$emit('progress', true);
				this.loading.facebook = true;
				window.FB.login((response) => {
					this.loading.facebook = false;
					this.$emit('progress', false);
					if (response.status == 'connected' && response.authResponse) {
						var grantedScopes = String(response.authResponse.grantedScopes).split(",");
						if ( ! grantedScopes.includes('email')) {
							this.$store.dispatch('alert/set', {type: 'error', content: this.$vuetify.lang.t('$vuetify.signin.alert.access.facebook')});
							this.$store.dispatch('alert/show');
						} else {
							this.loading.facebook = true;
							this.$emit('progress', true);

							var token = response.authResponse.accessToken;
							window.FB.api('/me', {fields: 'email'}, (user) => {
								this.$store.dispatch("auth/signin-facebook", { 
									token: token
								}).then(() => {
									this.$store.commit("auth/setIdentifier", user.email);
									this.$router.push({ name: 'signin-success', query: this.query });
								}).catch(error => {
									if (error.response && error.response.status == 403) {
										this.$store.commit("auth/setIdentifier", user.email);
										this.$router.push({ name: 'signin-suspended', query: this.query });
									} else if (error.response && error.response.status == 404) {
										this.$router.push({ name: 'signup-request', query: { ...this.query, ...{ provider: 'facebook', token: token }} });
									} else {
										this.loading.facebook = false;
										this.$emit('progress', false);
										this.$store.dispatch('alert/set', {type: 'error', content: this.$vuetify.lang.t('$vuetify.signin.alert.access.facebook')});
										this.$store.dispatch('alert/show');
									}
								});
							});
						}
					} else {
						this.$store.dispatch('alert/set', {type: 'error', content: this.$vuetify.lang.t('$vuetify.signin.alert.access.facebook')});
						this.$store.dispatch('alert/show');
					}
				}, {
					scope: 'public_profile,email', 
					auth_type: 'rerequest', 
					return_scopes: true
				});
			}
		},
		next() {
			this.$store.dispatch('alert/hide');
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			this.error = null;
			this.$emit('progress', true);

			this.$store.dispatch("auth/identifier", { 
				username: this.identifier 
			}).then(() => {
				this.$router.push({ name: 'signin-password', query: this.query });
			}).catch(error => {
				this.$emit('progress', false);
	            this.error = 'server';
				if (error.response) {
		            switch(error.response.status) {
					  	case 404:
					   	 	this.error = 'notfound';
					    	break;
					  	default:
					    	this.error = 'server';
					}
		        } 

				this.$refs.identifier.focus();
			});
		}
	}
}
</script>