<style lang="scss">
.transition-wrapper{
	position: relative;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-left-enter-active, .slide-left-leave-active, .slide-right-enter-active, .slide-right-leave-active {
	transition: all .3s cubic-bezier(0.4, 0, 0.2, 1);
	position: absolute;
	width: 100%;
}

.slide-left-enter, .slide-right-leave-to {
	transform: translateX(500px);
}

.slide-left-leave-to, .slide-right-enter {
	transform: translateX(-500px);
}
</style>
<template>
	<div>
	    <v-card
	      light
	      :disabled="progress"
	      :loading="progress"
	      :style="style"
	    >
	    	<div class="pa-10">
		    	<v-img
		    	  class="mx-auto mb-5"
				  :alt="`${$whitelabel.getName()} Logo`"
				  width="120"
				  style="vertical-align: middle;"
				>
					<img :src="`${$whitelabel.getPublicPath()}img/logo.png`" style="width: 100%; height: 100%;" />
				</v-img>
				<div class="transition-wrapper">
					<transition :name="transitionName">
				        <router-view @progress="setProgress" />
					</transition>
				</div>
			</div>
	    </v-card>

	    <Footer />
	</div>
</template>

<script>
import Footer from "@/components/Footer";

export default {
    name: "SignIn",
    components: {
    	Footer
    },
    data: () => ({
        progress: true,
        transitionName: 'slide-left'
    }),
    computed: {
    	style() {
    		if ( ! this.$vuetify.breakpoint.xsOnly) {
    			return 'width:450px;min-height: 500px;overflow: hidden;max-width:450px;';
    		}

    		return '';
    	}
    },
    watch: {
		$route (to, from) {
			if (['signin-identifier', 'signin-password'].includes(from.name) && ['signin-password', 'signin-success', 'signin-suspended'].includes(to.name)) {
				this.transitionName = 'slide-left';
			} else {
				this.transitionName = 'slide-right';
			}
		}
	},
	mounted() {
		this.$emit('progress', false);
	},
    methods: {
        setProgress (progress) {
            this.progress = progress;
        }
    }
}
</script>