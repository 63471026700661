<template>
	<div>
		<div class="text-center mb-10">
			<h1 class="headline mb-2">
				{{ $vuetify.lang.t('$vuetify.signin.suspended.title', { brand: $whitelabel.getClient().name }) }}
			</h1>
			<v-chip
				class="mb-0"
				outlined
				link
				@click="signout"
			>
				<v-avatar left>
					<v-icon color="secondary">
						mdi-account-circle
					</v-icon>
				</v-avatar>
				{{ $store.state.auth.identifier }}
				<v-avatar right>
					<v-icon color="secondary">
						mdi-chevron-down
					</v-icon>
				</v-avatar>
			</v-chip>
		</div>

		<div class="text-body-2 text--secondary" style="white-space: pre-line;">
			{{ $vuetify.lang.t('$vuetify.signin.suspended.subtitle', { email: $store.state.auth.identifier, brand: $whitelabel.getClient().name }) }}
		</div>
		<div class="d-flex justify-space-between mt-10">
			<v-btn
			  class="text-none ml-auto"
			  style="min-width: 88px;"
			  color="primary"
			  depressed
			  target="_blank"
			  :href="'mailto:support@botika.online?subject=Reactivate Account&body=Please reactivate my Botika Account: '+$store.state.auth.identifier"
			>
				{{ $vuetify.lang.t('$vuetify.signin.suspended.action.next') }}
			</v-btn>
		</div>
	</div>
</template>

<script>
export default {
	name: 'signin-suspended',
	props: {
		query: { type: Object, default() { return {} } },
	},
	metaInfo() {
      	return {
	      	title: this.$vuetify.lang.t('$vuetify.signin.suspended.title', { brand: this.$whitelabel.getClient().name }),
	      	titleTemplate: '%s | ' + this.$vuetify.lang.t('$vuetify.header.title', { brand: this.$whitelabel.getClient().name }),
	      	htmlAttrs: { lang: this.$i18n.locale }
      	}
    },
	mounted() {
		this.$emit('progress', false);
	},
	methods: {
		signout() {
			this.$emit('progress', true);
			this.$store.dispatch("auth/signout").then(response => {
				this.$router.push({ name: 'signin-identifier', query: this.query });
			}).catch(error => {
				this.$router.push({ name: 'rejected-server', query: this.query });
			});
		}
	}
}
</script>